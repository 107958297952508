button:disabled {
  cursor: default;
}

.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-wrapper > * {
  pointer-events: all;
}

.sidebar {
  background-color: #390939; 
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:#390939;
  color: whitesmoke;
  padding: 10px;
}

nav label {
  font-size: 25px;
  cursor: pointer;
}

nav .menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  list-style-type: none;
  background-color:#390939;
  padding: 10px;
}

nav .menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color:#390939;
  padding: 10px;
}

nav .menu li {
  margin-bottom: 10px;
}

nav .menu a {
  display: block;
  color: #B1B1A8;
  font-size: 17px;
  text-decoration: none;
  transition: all 0.3s ease;
}

nav .menu a:hover {
  color: #FAC70E;
}

.sidebar-nav .nav-link.active {
  background-color: #FAC70E;
}

@media screen and (max-width: 768px) {
  .banner-container {
    height: 80px !important;
  }

  .icon-container {
    left: 10px !important;
  }

  .bannerArea {
    width: 0;
  }
  
  .header {
    top: 80px !important;
  }
      
  .body {
    padding-top: 100px !important;
    padding-bottom: 30px;
  }
    
  .gradient-box {
    width: 130px !important;
  }
}

@media only screen and (min-width: 768px) {
  .header {
    top: 120px !important;
  }

  .body {
    padding-top: 140px !important;
    padding-bottom: 30px;
  }


  nav label {
    display: none;
  }
  nav .menu {
    display: flex;
    position: static;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
  nav .menu li {
    margin: 0 20px;
  }
}

#stepper-mobile-label {
  display: none;
  text-align: center;
}

@media only screen and (max-width: 630px) {
  #RFS-Label {
    display: none;
  }

  #stepper-mobile-label {
    display: block;
  }

  .address-information {
    flex: auto;
    padding-top: 20px;
  }
  
  #cookie_box {
    height: 150px!important;
    min-height: 150px;
  }
}

.MyAccountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textAccountCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.MyAccountCard {
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 20px;
  max-width: 400px;
  width: 100%;
  height: 350px;
}


.container {
  display: flex;
  max-width: 100%;
  padding: 0;
}

.container-lg {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .MyAccountContainer {
    flex-direction: row;
  }
}

.form-check-input:checked {
  background-color: var(--cui-form-check-input-checked-bg-color, black);
  border-color: var(--cui-form-check-input-checked-border-color, black);
}

.formButton {
  background: #e5ac00;
  border-color:#E5AC02;
  color: black;
  border-radius: 0%
}

.btn-dark {
  --cui-btn-bg: #120F0A;
  --cui-btn-border-color: #120F0A;
  --cui-btn-hover-bg: #120f0ab1;
  --cui-btn-hover-border-color: #120f0ab1;
}

.btn-danger {
  --cui-btn-bg: #e40046;
  --cui-btn-border-color: #e40046;
  --cui-btn-hover-bg: #e40046b1;
  --cui-btn-hover-border-color: #e40046b1;
  color: white
}

.btn-warning {
  --cui-btn-bg: #FAC70E;
  --cui-btn-border-color: #FAC70E;
  --cui-btn-hover-bg: #FAC70Eb1;
  --cui-btn-hover-border-color: #FAC70Eb1;
}

.btn-success {
  --cui-btn-bg: #00D6A3;
  --cui-btn-border-color: #00D6A3;
  --cui-btn-hover-bg: #00D6A3b1;
  --cui-btn-hover-border-color: #00D6A3b1;
}

.mb-3 {
  border-radius: 0 !important;
}

.formButton:disabled {
  background-color: #E5AC02;
  color: black;
  border-color:#E5AC02;
  border-radius: 0%
}

.formButton:hover {
  background-color: #d19d00;
  color: white;
  border-color: #d19d00;
  border-radius: 0%;
}

.form-control {
  border-radius: 0%;
}

.form-control-address {
  border-radius: 0%;
  border: $input-border-width solid var(--#{$prefix}input-border-color, $input-border-color) !important;
}

.error-text {
  color: #C6007E
}

#error-email {
  display: none;
}

#error-recaptcha {
  display: none;
}

.form-select {
  border-radius: 0 !important;
}

.invisible {
  display: none;
}

.red-asterisk {
  color: #C6007E;
}

.invalid-feedback-custom {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e55353;
}

.invalid-feedback-custom-visible {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e55353;
}

.form-control-loqate {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-custom-invalid {
  border-color: #e55353 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e55353%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e55353%27 stroke=%27none%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  @include ltr-rtl-value-only("background-position", right $input-height-inner-quarter center, left $input-height-inner-quarter center);
  background-size: $input-height-inner-half $input-height-inner-half;

}

/* The container for the custom checkbox */
.checkbox-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Hide the default checkbox */
.checkbox-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox style */
.checkbox-custom {
  width: 26px;
  height: 26px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
}

/* Style for the checked state */
.checkbox-container input[type="checkbox"]:checked + .checkbox-custom {
  border-color: #000000;
}

/* Custom checkmark using the ::before pseudo-element */
.checkbox-custom::before {
  content: '\2713'; /* Unicode checkmark symbol */
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: black;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  visibility: hidden;
}

/* Show the checkmark when the checkbox is checked */
.checkbox-container input[type="checkbox"]:checked + .checkbox-custom::before {
  visibility: visible;
}

.ccol-image {
  width: 100%
}

#cookie_box {
  color: white;
  position: fixed;
  display: none;
  bottom: 0;
  z-index: 9999999;
  background: rgba(0,0,0,.8);
  width: 100%;
  height: 5.5vh;
  min-height: 40px;
}

.cookie_box_close {
  color: black;
  text-decoration: none;
}

.cookwrap {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

#cookie_box .btncook {
  margin-left: 13px;
  padding: 5px;
  border: 0;
  border-radius: 5px;
}

.cookwrap button, .cookwrap p {
  display: inline-block;
}

/* Removes up/down from number field Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Removes up/down from number field Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#RFS-StepMain {
  font-family: var(--cui-body-font-family);
}

#RFS-Label {
  font-weight: 400;
}

.btn-success:disabled {
  background-color: grey !important
}

.banner-container {
  height: 120px;
  width: 100%;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1029;
}

.gradient-box {
  width: 220px;
  height: 100%;
  background: linear-gradient(to right, rgba(0,0,0,0.6) 80%, transparent);
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
}

.icon-container {
  position: absolute;
  top: 0;
  left: 50px;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.topicon {
  height: 80%;
}

.indented-label {
  padding-left: 20px;
}